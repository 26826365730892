body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  transition: all 0.2s;
}

/* Removing parts of the asurion theme */
.asurion-theme .a-input {
  border: none;
  margin: 0px !important;
}

.asurion-theme .a-input:focus {
  border: none;
}

.asurion-theme .a-input:hover:not(:focus):not(:disabled) {
  border: none;
}

.asurion-theme .a-textarea,
.asurion-theme .a-textarea:focus,
.asurion-theme .a-textarea:hover:not(:focus):not(:disabled) {
  border: none;
}

.asurion-theme .a-select,
.asurion-theme .a-select:hover:not(:focus):not(:disabled),
.asurion-theme .a-select:focus {
  border: none;
}

/* Removing Materialize */
input:not([type]),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
input[type='search']:not(.browser-default),
textarea.materialize-textarea {
  border-bottom: none;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}

.table th {
  position: relative;
}

@keyframes Loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.row {
  display: flex;
  margin: 0px;
}

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  margin-right: 5px;
}

tr {
  border: none !important;
}

td,
th {
  padding: 0px 10px !important;
  font-size: 12px;
}

@keyframes SlideOut {
  0% {
    height: 0 !important;
  }
  100% {
    height: 250px !important;
  }
}

input[type='range'] {
  border: 4px solid white;
}

input[type='checkbox'] {
  pointer-events: visible !important;
}

[type='radio']:not(:checked),
[type='radio']:checked {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: none;
}

:root {
  --cloud-fart: #f0f0f5;
  --gulag-of-my-soul: #6e767d;
  --grimace: #8223d2;
  --diet-grimace: #f3e9fb;
}

.ttd-text-link {
  color: var(--grimace);
}

::-webkit-input-placeholder {
  color: var(--gulag-of-my-soul);
}

/* Chrome/Opera/Safari */
::-moz-placeholder {
  color: var(--gulag-of-my-soul);
}

/* Firefox 19+ */
:-ms-input-placeholder {
  color: var(--gulag-of-my-soul);
}

/* IE 10+ */
:-moz-placeholder {
  color: var(--gulag-of-my-soul);
}

/* Firefox 18- */

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

input {
  margin: 0 !important;
}

.ttd-input-pill {
  border-radius: 16px;
}

button:focus {
  //outline: none;
  background-color: transparent;
}

.ttd-container {
  height: calc(100vh);
  display: flex;
}

.ttd-side-nav {
  width: 220px;
  height: 100%;
  background-color: var(--cloud-fart);
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.ttd-fold-side-nav {
  overflow: hidden;
  width: 68px !important;
}

.ttd-side-nav-padding {
  padding: 26px 8px;
}

.ttd-side-nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}

.ttd-side-nav-link,
.ttd-side-nav-link-is-active {
  outline: none;
  padding: 26px;
  font-weight: 800;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 60px;
  width: 220px;
}

//.ttd-side-nav-link-is-active {
//  background-color: white;
//}

.ttd-side-nav-link-is-inactive {
  opacity: 0.6;
}

.ttd-placeholder {
  color: var(--gulag-of-my-soul);
}

.ttd-text {
  font-size: 16px;
}

.ttd-date-picker-triangle {
  height: 20px;
  position: absolute;
  width: 20px;
  right: 50px;
  top: 55px;
  border-radius: 5px;
  z-index: 97;
  background: white;
  transform-origin: 25px 25px;
  transform: rotate(45deg);
  box-shadow: 5px 5px 4px 7px rgba(0, 0, 0, 0.2);
}

.ttd-show-date-picker {
  border: 1px solid #8223d2;
  box-shadow: 0 7px 6px 2px rgba(0, 0, 0, 0.2);
  height: 425px !important;
}

.ttd-date-picker {
  margin-top: -2px;
  border-radius: 0 0 4px 4px;
  background: white;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 99999;
}

.ttd-reporting-container > * {
  margin-top: 15px;
}

.ttd-column-resizer {
  background: transparent;
  border-left: 3px solid rgba(150, 150, 150, 0.3);
  max-width: 5px;
}

.ttd-close-icon {
  visibility: hidden;
}

th:hover > div > .ttd-close-icon {
  visibility: visible;
  cursor: pointer;
}

.ttd-flex-center {
  display: flex;
  align-items: center;
}

.ttd-td-row-height-collapsed {
  height: 0px;
  overflow: hidden;
}

.ttd-td-row-height-expanded {
  height: max-content;
}

.ttd-toast-display {
  position: absolute;
  bottom: 25px;
  right: 25px;
  padding: 15px 25px;
  height: auto;
  width: 200px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px 7px rgba(0, 0, 0, 0.2);
}

.ttd-toast-hide {
  height: 0px;
  width: 0px;
  visibility: hidden;
  position: absolute;
  bottom: 25px;
  right: 0;
}

.ttd-bare-button,
.ttd-bare-button:hover,
.ttd-bare-button:active,
.ttd-bare-button:focus {
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
}

.ttd-flex {
  display: flex;
}

.ttd-reporting-filter {
  display: flex;
  height: 100%;
  width: 600px;
  min-width: 300px;
}

.ttd-reporting-filter-expand {
  width: 50px;
  min-width: 50px;
}

.ttd-option-container {
  position: absolute;
  min-width: 100%;
  width: max-content;
  max-height: 165px;
  overflow-y: scroll;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  z-index: 9005;
}

.ttd-dropdown-container {
  position: relative;
}

.ttd-tooltip {
  color: var(--gulag-of-my-soul);
}
