* {
  transition: all 0.2s;
}

/* Removing parts of the asurin theme */
.asurion-theme .a-input {
  border: none;
  margin: 0px !important;
}

* {
  transition: all 0.2s;
}

.asurion-theme .a-input:focus {
  border: none;
}

.asurion-theme .a-input:hover:not(:focus):not(:disabled) {
  border: none;
}

.asurion-theme .a-textarea,
.asurion-theme .a-textarea:focus,
.asurion-theme .a-textarea:hover:not(:focus):not(:disabled) {
  border: none;
}

.asurion-theme .a-select,
.asurion-theme .a-select:hover:not(:focus):not(:disabled),
.asurion-theme .a-select:focus {
  border: none;
}

/* Removing Materialize */
input:not([type]),
input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default),
input[type='email']:not(.browser-default),
input[type='url']:not(.browser-default),
input[type='time']:not(.browser-default),
input[type='date']:not(.browser-default),
input[type='datetime']:not(.browser-default),
input[type='datetime-local']:not(.browser-default),
input[type='tel']:not(.browser-default),
input[type='number']:not(.browser-default),
input[type='search']:not(.browser-default),
textarea.materialize-textarea {
  border-bottom: none;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: none;
  box-shadow: none;
}

button:focus {
  background-color: transparent;
}

.table th {
  position: relative;
}

@keyframes Loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ttd-loading-animation {
  animation: Loading 0.85s reverse infinite;
}

.row {
  display: flex;
  margin: 0px;
}

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  margin-right: 5px;
}

ttd-tr {
  border: none !important;
}

ttd-td,
ttd-th {
  padding: 0px 10px !important;
  font-size: 12px;
}

@keyframes SlideOut {
  0% {
    height: 0 !important;
  }
  100% {
    height: 250px !important;
  }
}

input[type='range'] {
  border: 4px solid white;
}

[type='radio']:not(:checked),
[type='radio']:checked {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: none;
}

:root {
  --cloud-fart: #f0f0f5;
  --gulag-of-my-soul: #6e767d;
  --grimace: #8223d2;
  --diet-grimace: #f3e9fb;
}

.ttd-text-link {
  color: var(--grimace);
}

::-webkit-input-placeholder {
  color: var(--gulag-of-my-soul);
}

/* Chrome/Opera/Safari */
::-moz-placeholder {
  color: var(--gulag-of-my-soul);
}

/* Firefox 19+ */
:-ms-input-placeholder {
  color: var(--gulag-of-my-soul);
}

/* IE 10+ */
:-moz-placeholder {
  color: var(--gulag-of-my-soul);
}

/* Firefox 18- */

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

input {
  margin: 0 !important;
}

.ttd-input-pill {
  border-radius: 16px;
}

button:focus {
  background-color: transparent;
}

.ttd-container {
  height: calc(100vh);
  display: flex;
}

.ttd-side-nav {
  width: 220px;
  height: 100%;
  background-color: var(--cloud-fart);
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.ttd-fold-side-nav {
  overflow: hidden;
  width: 68px !important;
}

.ttd-side-nav-padding {
  padding: 26px 8px;
}

.ttd-side-nav-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
}

.ttd-side-nav-link,
.ttd-side-nav-link-is-active {
  outline: none;
  padding: 26px;
  font-weight: 800;
  letter-spacing: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 60px;
  width: 220px;
}

.ttd-side-nav-link-is-active {
  background-color: white;
}

.react-calendar {
  min-width: 350px;
  width: 350px;
  max-width: 100%;
  background: white;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--diet-grimace);
  border-radius: 50%;
}

.react-calendar__tile--now {
  background: var(--diet-grimace);
  border-radius: 50%;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--diet-grimace);
  border-radius: 50%;
}

.react-calendar__tile--hasActive {
  background: var(--diet-grimace);
  border-radius: 50%;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--diet-grimace);
  border-radius: 50%;
}

.react-calendar__tile--active {
  background: var(--grimace);
  border-radius: 50%;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--grimace);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

nav {
  background: #ffffff;
  height: 80px;
  line-height: 80px;

  .nav-wrapper {
    border-bottom: 1px solid #e6e6eb;

    &-expert-btn {
      margin: 0px 15px;
      width: 175px;
      height: 48px;
      text-transform: none;
      border-radius: 999px;
    }

    .underline {
      border-bottom: 4px solid #8223d2;
    }

    section,
    section > div {
      display: flex;
      align-items: center;
    }

    ul {
      float: left;

      a {
        color: #000000;
        font-size: 16px;
      }
    }
  }

  .version {
    font-size: 10px;
    color: #aaaaaa;
    position: absolute;
    left: 16px;
    top: 20px;
    height: 15px;
  }

  a {
    color: #000000;
  }
}

.waves-effect {
  color: black;
}

.new-waves-effect {
  color: white;

  &:hover {
    background: #555;
  }
}

.logo-wrapper {
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 64px;
  margin-right: 50px;

  img:nth-child(1) {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .nav-wrapper {
    section > div {
      justify-content: flex-end;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 993px) {
  .nav-wrapper {
    section {
      justify-content: space-between;
    }
  }
}

.ttd-side-nav-link-is-inactive {
  opacity: 0.6;
}

.ttd-placeholder {
  color: var(--gulag-of-my-soul);
}

.ttd-text {
  font-size: 16px;
}

.ttd-date-picker-triangle {
  height: 20px;
  position: absolute;
  width: 20px;
  right: 50px;
  top: 55px;
  border-radius: 5px;
  z-index: 97;
  background: white;
  transform-origin: 25px 25px;
  transform: rotate(45deg);
  box-shadow: 5px 5px 4px 7px rgba(0, 0, 0, 0.2);
}

.ttd-show-date-picker {
  border: 1px solid #8223d2;
  box-shadow: 0 7px 6px 2px rgba(0, 0, 0, 0.2);
  height: 425px !important;
}

.ttd-date-picker {
  margin-top: -2px;
  border-radius: 0 0 4px 4px;
  background: white;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 99999;
}

.ttd-reporting-container > * {
  margin-top: 15px;
}

.ttd-column-resizer {
  background: transparent;
  border-left: 3px solid rgba(150, 150, 150, 0.3);
  max-width: 5px;
}

.ttd-close-icon {
  visibility: hidden;
}

th:hover > div > .ttd-close-icon {
  visibility: visible;
  cursor: pointer;
}

.ttd-flex-center {
  display: flex;
  align-items: center;
}

.ttd-td-row-height-collapsed {
  height: 0px;
  overflow: hidden;
}

.ttd-td-row-height-expanded {
  height: max-content;
}

.ttd-toast-display {
  position: absolute;
  bottom: 25px;
  right: 25px;
  padding: 15px 25px;
  height: auto;
  width: 200px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px 7px rgba(0, 0, 0, 0.2);
}

.ttd-toast-hide {
  height: 0px;
  width: 0px;
  visibility: hidden;
  position: absolute;
  bottom: 25px;
  right: 0;
}

.ttd-bare-button,
.ttd-bare-button:hover,
.ttd-bare-button:active,
.ttd-bare-button:focus {
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
}

.ttd-flex {
  display: flex;
}

.ttd-sortable-item:hover {
  background: var(--cloud-fart);
}

.ttd-reporting-filter {
  display: flex;
  height: 100%;
  width: 600px;
  min-width: 600px;
}

.ttd-reporting-filter-expand {
  width: 50px;
  min-width: 50px;
}

.ttd-option-container {
  position: absolute;
  min-width: 100%;
  width: max-content;
  max-height: 165px;
  overflow-y: scroll;
  box-shadow: 0px 2px 4px 0.4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: white;
  z-index: 9005;
}

.ttd-dropdown-container {
  position: relative;
}

.ttd-tooltip {
  color: var(--gulag-of-my-soul);
}

/* Custom properties */

:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */

.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Absolute positioning */

.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 150%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: wrap;
  width: max-content;
  max-width: 10rem;
}

/* CSS border triangles */

.Tooltip-Tip::before {
  content: ' ';
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */

.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */

.Tooltip-Tip.top::before {
  top: 90%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */

.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */

.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */

.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}

/* CSS border triangles */

.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */

.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* CSS border triangles */

.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

//.genericLabelText {
//  color: black;
//  font-weight: 600;
//  letter-spacing: 0.8px;
//}

/* Dark mode */
.darkMode {
  --bold-header: #6efac3;
  --subtle-contrast-color: #555;
  --neutral-white: whitesmoke;
  --modal-backdrop: grey;
  --text-color: rgba(240, 240, 245, 1);
  --mdc-theme-secondary: #4a68f9;
  --mdc-theme-primary: rgba(168, 57, 243, 1);
  --asurion-purple: rgba(168, 57, 243, 1);
  --background-color-offset: #191919;
  --background-color: #262626;
  --expanded-row: #363636;
  --asurion-light-purple: rgba(203, 90, 255, 0.7);
  --background-hover-color: rgba(168, 57, 243, 0.1);
  //}
  color: var(--text-color);
  background-color: var(--background-color);

  nav .nav-wrapper,
  .ttd-side-nav {
    background-color: #191919;
  }

  .ttd-sortable-item:hover {
    background: var(--subtle-contrast-color);
  }

  // .waves-effect {
  //   color: green;
  // }
}

//  .text-with-arrow {
//    border-color: var(--border);
//  }
//  .MuiOutlinedInput-input {
//    &::-webkit-calendar-picker-indicator {
//      filter: invert(75%);
//    }
//  }
//  .label-text, .ttd-text, .ttd-bare-button, .genericLabelText, .ttd-bare-button, textarea {
//    color: var(--text-color);
//  }
//
//  .inputContainer label {
//    color: var(--text-color);
//  }
//  #filter-input.modal-input {
//    border-color: var(--border);
//  }
//
//  .dropdown-option {
//    background-color: var(--expanded-row);
//  }
//
//  #ubif-subsection > span {
//    font-weight: 500;
//    color: #00e7a1;
//    font-size: 18px;
//  }
//
//  .cta-text, .ttd-text {
//    color: #00e7a1;
//  }
//
//  nav .nav-wrapper, .ttd-side-nav {
//    background-color: #191919;
//  }
//
//  tr {
//    border-color: #36393e;
//  }
//  .ttd-side-nav-link-is-active {
//    background-color: #262626;
//  }
//}
